<template>
  <el-dialog
    title="編輯自動取消預約時間"
    :visible="true"
    :close-on-click-modal="false"
    width="610px"
    @close="$emit('close')"
  >
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item label="未付款自動取消預約時間" prop="paymentDeadlineMinute">
        <el-input v-model="formData.paymentDeadlineMinute" placeholder="請輸入">
          <template #suffix> 分鐘</template>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        :loading="loading"
        type="primary"
        data-cy="update-time-parameter"
        @click="onSubmit"
      >
        儲存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { get, map, filter, find } from 'lodash'
import { passValue } from '@/utils/helper'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { isDigitRules, integerRules, noEmptyRules, rangeRules } from '@/validation'
// import { usePermissions } from '@/use/permissions'
import { UpdateDepositConfig } from '@/api/deposit'
import { UpdateReservationCheckoutConfig }  from '@/api/reservation'

export default defineComponent({
  name: 'EditPaymentDeadlineModal',
  props: {
    configData: { type: Object, default: () => ({}) },
    activeTab: { type: String, default: '' },
  },
  setup (props, { emit }) {
    const { formRef, initFormData, formData, checkForm, loading } = useBaseForm()
    const { shopId } = useShop()
    const formRules = computed(() => {
      return {
        paymentDeadlineMinute: [noEmptyRules(), integerRules()],
      }
    })

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) {
        loading.value = false
        return
      }
      if (props.activeTab === 'deposit') {
        const [, err] = await UpdateDepositConfig({
          shopId: shopId.value,
          paymentDeadlineMinute: formData.paymentDeadlineMinute,
        })
        if (err) window.$message.error(err)
      }
      if (props.activeTab === 'checkout') {
        const [, err] = await UpdateReservationCheckoutConfig({
          shopId: shopId.value,
          paymentDeadlineMinute: formData.paymentDeadlineMinute,
        })
        if (err) window.$message.error(err)
      }

      loading.value = false
      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      if (props.configData) {
        initFormData({
          paymentDeadlineMinute: passValue(get(props.configData[props.activeTab], 'paymentDeadlineMinute')),
        })
      }
    })

    return {
      formRef,
      onSubmit,
      loading,
      formRules,
      formData,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
