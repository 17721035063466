<template>
  <section class="reservation-payment-settings settings-section section-block">
    <SectionTitle title="目前收款模式設定" @edit="modal.edit = true" />
    <el-form label-position="left" label-width="250px">
      <el-form-item label="收款模式類型"> {{ displayData.chargeType }} </el-form-item>
    </el-form>

    <EditChargeTypeModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { reactive, defineComponent, computed } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import EditChargeTypeModal from './EditChargeTypeModal.vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'ReservationPaymentSettings',
  components: { SectionTitle, EditChargeTypeModal },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const modal = reactive({
      edit: false,
    })

    const chargeTypeConfig = {
      none: { label: '不收費', value: 'none' },
      deposit: { label: '訂金', value: 'deposit' },
      'deposit-disabled': { label: '訂金（未啟用）', value: 'deposit' },
      checkout: { label: '預約結帳', value: 'checkout' },
    }

    const displayData = computed(() => {
      const curChargeType = get(props.configData, 'reservation.chargeType')

      if (curChargeType === 'deposit') {
        const enabled = get(props.configData, 'deposit.enable', false)
        return {
          chargeType: enabled
            ? chargeTypeConfig.deposit.label
            : chargeTypeConfig['deposit-disabled'].label,
        }
      }

      return {
        chargeType: get(chargeTypeConfig, `${curChargeType}.label`, '-'),
      }
    })

    return { modal, displayData }
  },
})
</script>

<style lang="postcss" scoped></style>
