<template>
  <el-dialog
    title="編輯目前收款模式"
    :visible="true"
    :close-on-click-modal="false"
    width="610px"
    @close="$emit('close')"
  >
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item label="選擇收款模式" prop="chargeType" :error="chargeTypeError">
        <el-select v-model="formData.chargeType" @change="onChargeTypeChange">
          <el-option
            v-for="option in chargeTypeOptions"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <p>{{ hints[formData.chargeType] }}</p>

    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        :loading="loading"
        type="primary"
        data-cy="update-time-parameter"
        :disabled="!!chargeTypeError"
        @click="onSubmit"
      >
        儲存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { UpdateReservationParameter } from '@/api/reservation'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { get, map, filter, find } from 'lodash'
import { passValue } from '@/utils/helper'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { isDigitRules, noEmptyRules, rangeRules } from '@/validation'
import { usePermissions } from '@/use/permissions'
import { UpdateDepositConfig } from '@/api/deposit'

export default defineComponent({
  name: 'EditChargeTypeModal',
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { formRef, initFormData, formData, checkForm, loading } = useBaseForm()
    const { shopId } = useShop()
    const { checkAction } = usePermissions()

    const chargeTypeError = ref('')

    const chargeTypeOptions = computed(() => {
      const options = [
        { value: 'none', label: '不收費' },
        { value: 'deposit', label: '訂金' },
        { value: 'checkout', label: '預約結帳' },
      ]
      const omitList = []
      if (!checkAction('admin.appointmentDepositConfig.page')) omitList.push('deposit')
      if (!checkAction('admin.appointmentCheckoutConfig.page')) omitList.push('checkout')
      return filter(options, (option) => !omitList.includes(option.value))
    })

    const hints = {
      deposit:
        '在訂金模式下，系統會依照金額設定向消費者收取，但無法開立發票，也不會向消費者揭露服務項目的實際金額。',
      checkout: '在預約結帳模式下，由於系統可提供發票開立，因此需向消費者揭露服務項目的實際金額。',
    }

    const displayData = computed(() => {
      return {
        hint: hints[formData.chargeType] || '',
      }
    })

    const formRules = computed(() => {
      return {
        chargeType: [noEmptyRules()],
      }
    })

    const checkDepositSettingsValid = () => {
      if (get(props.configData, 'depositPayments.length', 0) === 0) return false
      if (get(props.configData, 'depositPayments.length', 0) === 1) {
        if (
          !get(props.configData, 'depositPayments[0].enable') ||
          get(props.configData, 'depositPayments[0].isRemove')
        ) {
          return false
        }
      }
      if (!get(props.configData, 'deposit.id')) return false
      return true
    }
    const checkCheckoutSettingsValid = () => {
      if (get(props.configData, 'checkoutPayments.length', 0) === 0) return false
      if (get(props.configData, 'checkoutPayments.length', 0) === 1) {
        if (
          !get(props.configData, 'checkoutPayments[0].enable') ||
          get(props.configData, 'checkoutPayments[0].isRemove')
        ) {
          return false
        }
      }
      if (!get(props.configData, 'checkout.id')) return false
      return true
    }

    const onChargeTypeChange = (chargeType) => {
      if (chargeType === 'deposit') {
        if (!checkDepositSettingsValid()) {
          chargeTypeError.value = ''
          setTimeout(() => {
            chargeTypeError.value = '目前此類型的詳情內容尚未設定完畢，暫時無法選擇此收費方式'
          }, 50)
        } else {
          chargeTypeError.value = ''
        }
      } else if (chargeType === 'checkout') {
        if (!checkCheckoutSettingsValid()) {
          chargeTypeError.value = ''
          setTimeout(() => {
            chargeTypeError.value = '目前此類型的詳情內容尚未設定完畢，暫時無法選擇此收費方式'
          }, 50)
        } else {
          chargeTypeError.value = ''
        }
      } else {
        chargeTypeError.value = ''
      }
    }

    const onSubmit = async () => {
      if (chargeTypeError.value) return
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) {
        loading.value = false
        return
      }
      const [, err] = await UpdateReservationParameter({
        shopId: shopId.value,
        chargeType: formData.chargeType,
      })

      if (formData.chargeType === 'deposit') {
        const [, err] = await UpdateDepositConfig({
          shopId: shopId.value,
          id: get(props.configData, 'deposit.id'),
          enable: true,
        })
        if (err) window.$message.error(err)
      }

      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      if (props.configData) {
        const curChargeType = passValue(get(props.configData, 'reservation.chargeType'))
        if (!find(chargeTypeOptions.value, { value: curChargeType })) return
        initFormData({
          chargeType: passValue(get(props.configData, 'reservation.chargeType')),
        })
      }
    })

    return {
      formRef,
      onSubmit,
      loading,
      formRules,
      formData,
      chargeTypeOptions,
      displayData,
      onChargeTypeChange,
      chargeTypeError,
      hints,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
