<template>
  <section class="reservation-payment-settings settings-section section-block">
    <SectionTitle title="自動取消預約時間" @edit="modal.edit = true" />
    <el-form label-position="left" label-width="250px">
      <el-form-item label="未付款自動取消預約時間"> {{ displayData.paymentDeadlineMinute }} </el-form-item>
    </el-form>
    <EditPaymentDeadlineModal
      v-if="modal.edit"
      :configData="configData"
      :activeTab="activeTab"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { reactive, defineComponent, computed } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import EditPaymentDeadlineModal from './EditPaymentDeadlineModal.vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'PaymentDeadlineSettingsBlock',
  components: { SectionTitle, EditPaymentDeadlineModal },
  props: {
    configData: { type: Object, default: () => ({}) },
    activeTab: { type: String, default: '' },
  },
  setup (props) {
    const modal = reactive({
      edit: false,
    })

    const displayData = computed(() => {
      const paymentDeadlineMinute = get(props.configData[props.activeTab], 'paymentDeadlineMinute')
      return {
        paymentDeadlineMinute: paymentDeadlineMinute ? `${paymentDeadlineMinute} 分鐘` : '-',
      }
    })

    return { modal, displayData }
  },
})
</script>

<style lang="postcss" scoped></style>
