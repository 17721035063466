<template>
  <el-dialog
    title="編輯訂金設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form label-position="top">
      <!-- <el-form-item label="是否開啟訂金：" prop="enable">
        <el-switch
          v-model="syncFormData.enable"
          active-text="開啟"
          inactive-text="關閉"
          @change="resetFormValidate"
        />
      </el-form-item> -->

      <el-form-item v-if="useClassTicketCredit" label="堂票預約免付訂金：" prop="enable">
        <el-switch
          v-model="syncFormData.enableClassTicketCredit"
          active-text="開啟"
          inactive-text="關閉"
          @change="resetFormValidate"
        />
      </el-form-item>
    </el-form>
    <el-form ref="form" :model="syncFormData" :rules="formRules" label-position="top">
      <el-form-item ref="paymentMode" style="margin-bottom: 16px" label="收費模式設定：" prop="paymentMode">
        <el-radio-group
          v-model="syncFormData.paymentMode"
          style="margin-bottom: 10px"
          @change="resetItem(['cash', 'percentege'])"
        >
          <div class="radio-group">
            <RadioWithInput
              title="固定金額（$）"
              label="cash"
              prop="cash"
            >
              <div slot="help">
                <p class="popover-container">說明：系統將「根據您所設定的固定金額」計算每個服務的訂金金額；如果為多人預約，將「根據您所設定的固定金額 X 預約人數」計算</p>
              </div>
              <el-form-item ref="cash" prop="cash">
                <el-input
                  v-model="syncFormData.cash"
                  :disabled="syncFormData.paymentMode !== 'cash'"
                  placeholder="請輸入固定訂金金額"
                />
              </el-form-item>
            </RadioWithInput>
            <RadioWithInput
              title="固定比率（%）"
              label="percentege"
              prop="percentege"
            >
              <div slot="help">
                <p class="popover-container">系統將根據「每個服務實際價格 X 固定比率」計算每個服務的訂金金額；如果為多人預約，將以「預約人數 X 每個服務實際價格 X 固定比率」計算</p>
              </div>
              <el-form-item ref="percentege" prop="percentege">
                <el-input
                  v-model="syncFormData.percentege"
                  :disabled="syncFormData.paymentMode !== 'percentege'"
                  placeholder="請輸入百分比"
                />
              </el-form-item>
            </RadioWithInput>
          </div>
        </el-radio-group>
      </el-form-item>

      <el-form-item style="margin-bottom: 16px" label="收費對象設定：" prop="target">
        <el-radio-group v-model="syncFormData.target" style="margin-bottom: 10px" @change="resetItem(['memberInclude', 'memberExclude'])">
          <div class="radio-group" @change="resetMemberTag">
            <el-radio label="all">所有會員</el-radio>
            <el-radio label="advance">進階標籤會員</el-radio>
          </div>
        </el-radio-group>
        <div v-if="syncFormData.target === 'advance'">
          <div class="flex flex-col">
            <div>完全包含</div>
            <el-form-item ref="memberInclude" prop="memberInclude">
              <MemberTagSelect
                :data="tags.filter(i => !find(syncFormData.memberExclude, {id: i.id}))"
                placeholder="選取會員標籤"
                multiple
                :model.sync="syncFormData.memberInclude"
                @change="resetItem(['memberInclude', 'memberExclude'])"
              />
            </el-form-item>
          </div>

          <div>
            <div>排除</div>
            <el-form-item ref="memberExclude" prop="memberExclude">
              <MemberTagSelect
                :data="tags.filter(i => !find(syncFormData.memberInclude, {id: i.id}))"
                placeholder="選取會員標籤"
                multiple
                :model.sync="syncFormData.memberExclude"
                @change="resetItem(['memberInclude', 'memberExclude'])"
              />
            </el-form-item>
          </div>
        </div>
      </el-form-item>

      <el-form-item style="margin-bottom: 16px" label="收取訂金服務項目設定：">
        <el-radio-group
          v-model="syncFormData.services"
          style="margin-bottom: 10px"
          @change="resetItem(['servicesExclude', 'servicesInclude'])"
        >
          <div class="radio-group">
            <RadioWithInput
              label="exclude"
              title="全部 （可排除特定服務項目）"
            >
              <div slot="help">
                <p class="popover-container">選擇您想要免收訂金的特定服務項目，系統將針對其餘服務項目收取訂金</p>
              </div>
              <el-form-item ref="servicesExclude" prop="servicesExclude">
                <ServicesSelect
                  manual
                  multiple
                  :data="servicesList.filter(i => !find(syncFormData.servicesInclude, {id: i.id}))"
                  :disabled="syncFormData.services !== 'exclude'"
                  placeholder="請選擇免收訂金的服務項目"
                  :model.sync="syncFormData.servicesExclude"
                />
              </el-form-item>
            </RadioWithInput>

            <RadioWithInput
              label="include"
              title="只針對特定服務項目"
            >
              <div slot="help">
                <p class="popover-container">系統僅針對您設定的特定服務項目收取訂金</p>
              </div>
              <el-form-item ref="servicesInclude" prop="servicesInclude">
                <ServicesSelect
                  manual
                  multiple
                  :data="servicesList.filter(i => !find(syncFormData.servicesExclude, {id: i.id}))"
                  :disabled="syncFormData.services !== 'include'"
                  placeholder="請選擇您想要收取訂金的服務項目"
                  :model.sync="syncFormData.servicesInclude"
                />
              </el-form-item>
            </RadioWithInput>
          </div>
        </el-radio-group>
      </el-form-item>

      <el-form-item style="margin-bottom: 16px" label="收取訂金附加服務設定：" prop="attachServices">
        <el-radio-group
          v-model="syncFormData.attachServices"
          style="margin-bottom: 10px"
          @change="resetItem(['attachServicesExclude', 'attachServicesInclude'])"
        >
          <div class="radio-group">
            <RadioWithInput
              label="all"
              title="全部皆免收訂金"
              disabledHelp
            />
            <RadioWithInput
              label="exclude"
              title="全部 （可排除特定附加服務）"
            >
              <div slot="help">
                <p class="popover-container">提示：選擇您想要免收訂金的特定附加服務，系統將針對其餘附加服務收取訂金</p>
              </div>
              <el-form-item ref="attachServicesExclude" prop="attachServicesExclude">
                <ServicesSelect
                  multiple
                  manual
                  :data="attachServicesList.filter(i => !find(syncFormData.attachServicesInclude, {id: i.id}))"
                  :disabled="syncFormData.attachServices !== 'exclude'"
                  placeholder="請選擇免收訂金的服務項目"
                  :model.sync="syncFormData.attachServicesExclude"
                />
              </el-form-item>
            </RadioWithInput>

            <RadioWithInput
              title="只針對特定附加服務"
              label="include"
            >
              <div slot="help">
                <p class="popover-container">提示：系統僅針對您設定的特定附加服務收取訂金</p>
              </div>
              <el-form-item ref="attachServicesInclude" prop="attachServicesInclude">
                <ServicesSelect
                  multiple
                  manual
                  :data="attachServicesList.filter(i => !find(syncFormData.attachServicesExclude, {id: i.id}))"
                  :disabled="syncFormData.attachServices !== 'include'"
                  placeholder="請選擇您想要收取訂金的服務項目"
                  :model.sync="syncFormData.attachServicesInclude"
                />
              </el-form-item>
            </RadioWithInput>
          </div>
        </el-radio-group>
      </el-form-item>

      <el-form-item style="margin-bottom: 16px" label="收取訂金期間設定：" prop="enableDateRange">
        <div class="flex items-center">
          <p class="text-gray-100" style="margin-right: 12px">是否針對特定期間收取訂金</p>
          <el-radio-group
            v-model="syncFormData.enableDateRange"
          >
            <div class="flex">
              <el-radio style="margin-right: 12px" :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </div>
          </el-radio-group>
        </div>
        <p class="hint" style="margin-bottom: 9px">提示：如需針對特定節日（如：過年期間）收取訂金，請選擇「是」並設定日期區間</p>
        <div v-if="syncFormData.enableDateRange">
          <el-form-item prop="dateRange">
            <el-date-picker
              v-model="syncFormData.dateRange"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm"
              range-separator="至"
              start-placeholder="開始時間"
              end-placeholder="結束時間"
              format="yyyy-MM-dd HH:mm"
            />
            <p class="hint">提示：如預約此特定期間的服務需收取訂金</p>
          </el-form-item>
        </div>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="handleConfirm">
        確認
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getAllDataFromApi } from '@/utils/helper'
import { GetService, GetServiceCount } from '@/api/service'
import { GetAttachServiceCount, GetAttachService } from '@/api/attachService'

import { noEmptyRules, rangeRules, isDigitRules } from '@/validation'
import formUtils from '@/utils/form'
import RadioWithInput from '@/components/Form/RadioWithInput.vue'
import MemberTagSelect from '@/components/Select/MemberTagSelect.vue'
import ServicesSelect from '@/components/Select/ServicesSelect.vue'
import { mapGetters } from 'vuex'
import { find } from 'lodash'
import { computed, defineComponent } from 'vue'
import { useTagStore } from '@/views/Marketing/components/useTagStore'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'EditDepositDialog',
  components: { MemberTagSelect, ServicesSelect, RadioWithInput },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  setup () {
    const { tags, fetchTagsOnMounted } = useTagStore()
    const { checkAction } = usePermissions()

    fetchTagsOnMounted()

    const useClassTicketCredit = computed(() => checkAction('admin.appointmentDepositConfig.enableClassTicketCredit'))

    return {
      tags,
      useClassTicketCredit,
    }
  },
  data: () => ({
    servicesList: [],
    attachServicesList: [],
  }),
  computed: {
    ...mapGetters(['shop']),
    syncFormData: {
      get () {
        return this.formData
      },
      set (val) {
        return this.$emit('update', val)
      },
    },
    formRules () {
      const base = {
        enable: [noEmptyRules('', true)],
        paymentMode: [noEmptyRules('', true)],
        target: [noEmptyRules('', true)],
        // services: [noEmptyRules('', true)],
        attachServices: [noEmptyRules('請選擇附加服務設定', true)],
        enableDateRange: [noEmptyRules('', true)],
      }
      if (this.formData.paymentMode === 'cash') base.cash = [noEmptyRules('請輸入金額', true), isDigitRules()]
      else base.percentege = [noEmptyRules('請輸入百分比', true), rangeRules(0, 100), isDigitRules()]

      if (this.formData.services === 'include') base.servicesInclude = [noEmptyRules('請至少選擇一項', true)]
      // if (this.formData.services === 'exclude') base.servicesExclude = [noEmptyRules('', true)]

      if (this.formData.attachServices === 'include') base.attachServicesInclude = [noEmptyRules('請至少選擇一項', true)]
      // if (this.formData.attachServices === 'exclude') base.attachServicesExclude = [noEmptyRules('請至少選擇一項', true)]

      if (this.formData.enableDateRange) base.dateRange = [noEmptyRules('請選擇時間', true)]

      const memberInclude = this.formData.memberInclude
      const memberExclude = this.formData.memberExclude
      if (!memberInclude && !memberExclude) {
        base.memberInclude = [noEmptyRules('請至少選擇一項', true)]
        base.memberExclude = [noEmptyRules('請至少選擇一項', true)]
        return base
      }
      if (!memberInclude.length && !memberExclude.length) {
        base.memberInclude = [noEmptyRules('請至少選擇一項', true)]
        base.memberExclude = [noEmptyRules('請至少選擇一項', true)]
      }
      return base
    },
  },
  async mounted () {
    await Promise.all([
      this.getAllServices(),
      this.getAllAttachServices(),
    ])
    // this.syncFormData.enable = true
  },
  methods: {
    find,
    resetItem (refs) {
      refs.forEach(i => {
        const ref = this.$refs[i]
        if (ref) {
          setTimeout(() => {
            ref.clearValidate()
          }, 0)
        }
      })
    },
    async handleConfirm () {
      if (!this.formData.enable) return this.$emit('update')
      if (!(await formUtils.checkForm(this.$refs.form))) {
        this.$emit('error')
      } else {
        this.$emit('update')
      }
    },

    resetFormValidate () {
      formUtils.clearValidate(this.$refs.form)
    },

    resetMemberTag () {
      this.syncFormData.memberInclude = []
      this.syncFormData.memberExclude = []
    },

    // Service
    async getAllServices () {
      const max = await this.getServiceCount()
      // console.log('max', max)
      const config = {
        shopId: this.shop,
        start: 0,
        limit: 100,
      }
      console.log('config', config)
      const res = await getAllDataFromApi(
        max,
        this.getServices,
        config,
      )

      console.log('services', res)

      this.servicesList = res
    },
    async getServiceCount () {
      try {
        const res = await GetServiceCount({ shopId: this.shop })
        return res.data.count
      } catch (error) {
        console.log(error)
      }
    },
    async getServices (config) {
      try {
        const res = await GetService(config)
        console.log('res', res)
        return res
      } catch (err) {
        this.$message.error(err)
        console.log('GetService Err', err)
        return []
      }
    },
    // AttachServices
    async getAllAttachServices (query = undefined) {
      const max = await GetAttachServiceCount({ shopId: this.shop })
      const config = {
        shopId: this.shop,
        start: 0,
        limit: 20,
      }
      const res = await getAllDataFromApi(
        max.data.count,
        this.getAttachServices,
        config,
      )
      this.attachServicesList = res
    },
    async getAttachServices (config) {
      try {
        return await GetAttachService(config)
      } catch (err) {
        this.$message.error(err)
        console.log(err)
        return []
      }
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-select {
  @apply h-auto;
}

::v-deep .el-select__tags {
  @apply flex-nowrap;
}

::v-deep .el-radio {
  @apply mb-[4px];
}

::v-deep .radio-group {
  gap: 16px !important;
}

.radio-group {
  @apply flex flex-col gap-[8px];
}

.popover-container {
  @apply w-[200px] text-justify;
}

.hint {
  @apply text-gray-60 leading-[17px] text-sm font-normal;
}

::v-deep .el-form-item {
  /* @apply mb-[16px]; */
}
</style>>
